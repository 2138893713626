<style>
  .feather-icon{
    line-height: 24px;
    vertical-align: middle;
  }
</style>
<template>
  <b-col md="3" class="mt-1">
    <b-avatar class="ml-1" :variant="status" size="small" v-if="status">
      <feather-icon v-if="status === 'success'" icon="CheckIcon" />
      <feather-icon v-if="status === 'warning'" icon="AlertTriangleIcon" />
      <feather-icon v-if="status === 'danger'" icon="BellIcon" />
      <feather-icon v-if="status === 'grey'" icon="XCircleIcon" />
    </b-avatar>
    <span v-show="percent.length > 0" class="ml-1 font-bold align-middle" :class="[`text-${status} `]">{{percent}} <span v-if="percent != undefined">%</span></span>
    <span v-show="description.length > 0" class="ml-1 text-xs align-middle">{{description}}</span>
  </b-col>
</template>
<script>
import {
  BAvatar, BCol
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BCol
  },
  props: {
    status: String,
    percent: {
      type: String,
      default: ''
    },
    custom: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  }
}
</script>
