/* eslint no-async-promise-executor: 0 */ // --> OFF
import { app } from '@/main'
import axios from 'axios'
import { getToken, generateToken, validateToken } from '@/libs/session/index'
import { empty } from '@/libs/tools/helpers'
import Vue from 'vue'
import store from '@/store/index'

/*
params:{
    "url":"",
    "params":{},
    "method": "POST"|"GET"|"PUT"|"DELETE",
    "validate": true|false
}
*/

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL || ''
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.defaults.headers.post['Content-Type'] = 'application/json'

Vue.prototype.$http = axiosIns

export default async function request (params) {
  return new Promise(async function (resolve, reject) {
    const url = params.url || ''
    const data = params.params || ''
    const method = params.method || 'GET'
    const customMessages = params.customMessages || false
    let validate = params.validate
    let token = ''
    if (params.validate === undefined) validate = true

    if (validate) {
      if (!validateToken()) {
        token = await generateToken()
      } else token = getToken()
      axiosIns.defaults.headers.common.Authorization = `Bearer ${token}`
    }

    const methodSwitch = {
      GET: () => {
        store.commit('loading/SHOW')
        let tempdata = '?'
        for (const prop in data) {
          if (!empty(data[prop])) { tempdata += `${prop}=${data[prop]}&` }
        }
        tempdata = tempdata.slice(0, -1)
        axiosIns.get(`${url}${tempdata}`)
          .then(response => {
            resolve(response)
          }).catch(error => {
            if (error.response.data != null && error.response.data.code === 401) {
              store.commit('sessionExpired/SETEXPIRED')
              app.$cookies.remove('session')
              app.$router.push('/')
            }
            reject(error)
          }).finally(() => {
            store.commit('loading/CLOSE')
          })
      },
      POST: () => {
        store.commit('loading/SHOW')
        axiosIns.post(`${url}`,
          data)
          .then(response => {
            if (!customMessages) {
              app.$swal({
                title: app.$t('Messages.Success'),
                text: app.$t('Messages.AddSuccess'),
                timer: 5000,
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-success'
                },
                buttonsStyling: false
              })
            }
            resolve(response)
          }).catch(error => {
            if (error.response.data != null && error.response.data.code === 401) {
              store.commit('sessionExpired/SETEXPIRED')
              app.$cookies.remove('session')
              app.$router.push('/')
            }
            if (!customMessages) {
              app.$swal({
                title: app.$t('Messages.Fail'),
                text: app.$t('Messages.FailMessage'),
                timer: 5000,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-danger'
                },
                buttonsStyling: false
              })
            }
            reject(error)
          }).finally(() => {
            store.commit('loading/CLOSE')
          })
      },
      PUT: () => {
        store.commit('loading/SHOW')
        axiosIns.put(`${url}`,
          data)
          .then(response => {
            if (!customMessages) {
              app.$swal({
                title: app.$t('Messages.Success'),
                text: app.$t('Messages.UpdateSuccess'),
                timer: 5000,
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-success'
                },
                buttonsStyling: false
              })
            }
            resolve(response)
          }).catch(error => {
            if (error.response.data != null && error.response.data.code === 401) {
              store.commit('sessionExpired/SETEXPIRED')
              app.$cookies.remove('session')
              app.$router.push('/')
            }
            if (!customMessages) {
              app.$swal({
                title: app.$t('Messages.Fail'),
                text: app.$t('Messages.FailUpdate'),
                timer: 5000,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-danger'
                },
                buttonsStyling: false
              })
            }
            reject(error)
          }).finally(() => {
            store.commit('loading/CLOSE')
          })
      },
      DELETE: () => {
        store.commit('loading/SHOW')
        const config = {
          data: data
        }
        axiosIns.delete(`${url}`, config)
          .then(response => {
            if (!customMessages) {
              app.$swal({
                title: app.$t('Messages.Success'),
                text: app.$t('Messages.DeleteSuccess'),
                timer: 5000,
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-success'
                },
                buttonsStyling: false
              })
            }
            resolve(response)
          }).catch(error => {
            if (error.response.data != null && error.response.data.code === 401) {
              store.commit('sessionExpired/SETEXPIRED')
              app.$cookies.remove('session')
              app.$router.push('/')
            }
            if (!customMessages) {
              app.$swal({
                title: app.$t('Messages.Fail'),
                text: app.$t('Messages.FailDelete'),
                timer: 5000,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-danger'
                },
                buttonsStyling: false
              })
            }
            reject(error)
          }).finally(() => {
            store.commit('loading/CLOSE')
          })
      }
    }

    methodSwitch[method]()
  })
}
