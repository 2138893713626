<template>
  <b-card>
    <b-row>
      <div class="m-2">
        <b-button class="float-left" variant="primarySpore" @click="back()">Regresar</b-button>
      </div>
    </b-row>
    <div>
      <b-row class="mt-1">

        <b-col md="12" lg="3">
          <b-card class="card_statisc">
            <b-row>
              <b-col md="12" v-if="response.cfeCabinet">
                <h4>Número gabinete: </h4>
                <label class="font-medium-1 mb-1">{{response.cfeCabinet.number}}</label>
                <h4>Serie: </h4>
                <label class="font-medium-1 mb-1">{{response.serie}}</label>
                <h4>Tipo de gabinete: </h4>
                <label class="font-medium-1 mb-1" style="font-size: 20px">{{(response.cfeCabinet.type == 'P' ? 'Protecsa': response.type == 'N' ? 'Nansen': response.type == 'S' ? 'Siemens': 'Otro') }}</label>
                <h4>Agencia: </h4>
                <label class="font-medium-1 mb-1">{{response.cfeAgency.name}}</label><br>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col md="12" lg="3">
          <b-card class="card_statisc">
              <b-row>
                <b-col md="12">
                  <h4>Fecha instalación: </h4>
                  <label class="font-medium-1 mb-1">{{ response.installedAt }}</label>
                </b-col>
                <b-col md="12" class="mb-1">
                  <h4>Fecha ultima transmisión: </h4>
                  <label class="font-medium-1 mb-1">{{ response.transmitedAt }}</label>
                </b-col>
                <b-col md="12" class="mb-1">
                  <h4>Señal: </h4>
                  <icon-signal-percent class="align-middle" width="25" height="25" iconColor="#000" :percent="parseInt(response.sign)" />
                  <span class="align-middle">
                    {{ getSignalPercent(response.sign) }}
                  </span>
                </b-col>
              </b-row>
          </b-card>
        </b-col>

        <b-col cols="12" md="12">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-md-0">
                <label>Mostrar</label>
                <v-select v-model="filters.limit" :options="this.perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @input="getCabinetsLogs"/>
                <label>Registros</label>
              </b-col>
              <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-md-0">
                <label for="example-datepicker">Desde</label>&nbsp;
                <b-form-datepicker id="beginDate" placeholder="" v-model="beginDate" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-md-0">
                <label for="example-datepicker">Hasta</label>&nbsp;
                <b-form-datepicker id="finishDate" placeholder="" v-model="finishDate" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="12" md="3" class="mt-2 text-right">
                <b-button variant="primarySpore" @click="getCabinetsLogs">
                  <feather-icon icon="SearchIcon" class="mr-50"/>
                  <span>Buscar</span>
                </b-button>
              </b-col>
            </b-row>
          </div>

          <b-table
              v-if="responseTable.length > 0"
              class="position-relative"
              hover
              responsive
              primary-key="id"
              show-emptysomething
              :items="responseTable"
              :fields="tableColumns"
            >
              <template #cell(Action)="row">
                <b-form-checkbox v-if="row.item.createdAt" v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
                  <span class="vs-checkbox">
                    <span class="vs-checkbox--check">
                      <i class="vs-icon feather icon-check" />
                    </span>
                  </span>
                  <span class="vs-label">{{ row.detailsShowing ? 'Ocultar Alertas' : 'Mostrar Alertas' }}</span>
                </b-form-checkbox>
              </template>

              <template #row-details="row">
                <b-card>
                  <b-row class="mb-1">
                    <b-col md="12" class="mb-1">
                      <b-row class="mb-1">
                          <statusItem v-for="item in alerts" :key="item.name" :status="(item.value === '0' ?'success': item.value === '' ?'':'danger')" :description="item.label"/>
                      </b-row>
                    </b-col>
                  </b-row>

                  <b-button variant="outline-info" @click="row.toggleDetails">
                    Ocultar Alertas
                  </b-button>
                </b-card>
              </template>

              <template #cell(createdAt)="data">
                <div class="text-center">
                  {{ format(data.item.createdAt) }}
                </div>
              </template>
            </b-table>

          <div class="mx-2 mb-2">
            <b-row>

              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                <!--<span class="text-muted">Showing {{ count }} of {{ totalRows }} entries</span>-->
              </b-col>
              <!-- Pagination -->
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                <b-pagination v-model="currentPage" :total-rows="this.totalRows" :per-page="this.filters.limit" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18"/>
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18"/>
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-col>
        <br><br>
      </b-row>
    </div>

  </b-card>
</template>

<script>
// import { empty } from '@/libs/tools/helpers'
import { formatDateLanguages } from '@/libs/tools/helpers'
// import listItem from '@core/spore-components/listItem/listItem.vue'
import iconSignalPercent from '@core/spore-components/icons/iconSignalPercent.vue'
import statusItem from '@core/spore-components/statusItem/statusItem.vue'
import {
  BButton, BRow, BCol, BCard, VBTooltip, BTable, BPagination, BFormCheckbox, BFormDatepicker
} from 'bootstrap-vue'
import request from '@/libs/request/index'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BTable,
    BPagination,
    /* listItem, */
    iconSignalPercent,
    BFormCheckbox,
    statusItem,
    BFormDatepicker
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      uuid: this.$route.params.uuid,
      response: [],
      tableColumns: [
        { thClass: 'text-center', key: 'Action', label: 'Logs', thStyle: { width: '15%' } },
        { thClass: 'text-center', key: 'createdAt', sortable: true, label: 'Fecha de Creación' }
      ],
      alerts: [
        { name: 'absencePhaseA', label: 'Ausencia fase A', value: 0 },
        { name: 'absencePhaseB', label: 'Ausencia fase B', value: 0 },
        { name: 'absencePhaseC', label: 'Ausencia fase C', value: 0 },
        { name: '', label: '', value: '' },
        { name: 'openPassBox', label: 'Caja de paso abierta', value: 0 },
        { name: 'loadProfileError', label: 'Error en perfil de carga', value: 0 },
        { name: 'communicationError', label: 'Error de comunicación', value: 0 },
        { name: 'dateTimeInvalid', label: 'Fecha y Hora no válida', value: 0 },
        { name: 'fraudMeter1', label: 'Fraude medidor 1', value: 0 },
        { name: 'fraudMeter2', label: 'Fraude medidor 2', value: 0 },
        { name: 'fraudMeter3', label: 'Fraude medidor 3', value: 0 },
        { name: 'fraudMeter4', label: 'Fraude medidor 4', value: 0 },
        { name: 'fraudMeter5', label: 'Fraude medidor 5', value: 0 },
        { name: 'fraudMeter6', label: 'Fraude medidor 6', value: 0 },
        { name: 'fraudMeter7', label: 'Fraude medidor 7', value: 0 },
        { name: 'fraudMeter8', label: 'Fraude medidor 8', value: 0 },
        { name: 'fraudMeter9', label: 'Fraude medidor 9', value: 0 },
        { name: 'fraudMeter10', label: 'Fraude medidor 10', value: 0 },
        { name: 'fraudMeter11', label: 'Fraude medidor 11', value: 0 },
        { name: 'fraudMeter12', label: 'Fraude medidor 12', value: 0 },
        { name: 'simDoorOpen', label: 'Puerta SIM abierta', value: 0 },
        { name: 'hotSnapOcurred', label: 'Ocurrio Hot Swap', value: 0 },
        { name: 'temperatureSensorActivated', label: 'Sensor temperatura', value: 0 },
        { name: 'systemJustPoweredUp', label: 'Sistema energizado', value: 0 },
        { name: 'notPulse24Hrs', label: 'Sin pulso 24 Hr', value: 0 },
        { name: 'notConsumption', label: 'Sin consumo', value: 0 }
      ],
      totalRows: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 75, 100],
      perPage: 25,
      count: 0,
      responseTable: [],
      file: '',
      avatar: '',
      beginDate: '',
      finishDate: '',
      filters: {
        limit: 10,
        offset: 0,
        byColumn: 'id',
        byOrder: 'ASC',
        beginDate: '',
        finishDate: ''
      }
    }
  },
  methods: {
    format (date) {
      return formatDateLanguages(date, 'hour')
    },
    getSignalPercent (value) {
      value = parseInt(value)
      if (value === 99) return 'No conocido o no detectado'
      else if (value > 30) return '-52 dBm o muy buena'
      else if (value > 1 && value <= 30) return '-110... - 54 dBm'
      else if (value === 1) return '-111 dBm'
      else if (value === 0) return '-115 dBm o menor'
      return value
    },
    async getDetailDevice () {
      const params = {
        url: `/devices/${this.uuid}`,
        method: 'GET'
      }
      await request(params).then(data => {
        if (data.data.code === 200) {
          this.response = data.data.data
          this.getCabinetsLogs()
        }
      })
    },
    async getCabinetsLogs () {
      if (this.beginDate) {
        this.filters.beginDate = this.beginDate
      }
      if (this.finishDate) {
        this.filters.finishDate = this.finishDate
      }
      const params = {
        url: `/cabinets/log/list/${this.response.cfeCabinet.uuid}`,
        method: 'GET',
        params: this.filters
      }
      await request(params).then(data => {
        if (data.data.code === 200) {
          this.responseTable = data.data.data.rows
          this.totalRows = data.data.data.count
          this.count = data.data.data.rows.length
        }
      })
    },
    back () {
      this.$router.push(`/gabinets/${this.uuid}/detail`)
    }
  },
  created () {
    this.getDetailDevice()
  },
  watch: {
    currentPage (val) {
      this.filters.offset = (val - 1) * this.filters.limit
      this.getCabinetsLogs()
    },
    byColumn (val) {
      this.filters.byColumn = val
      this.currentx = 1
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
